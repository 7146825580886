import React from 'react';

import { Shortcuts } from 'react-shortcuts';

import TreeHeader from './TreeHeader';
import ScrollBar from "ScrollBar";
const SymbolData = ({
    filtersymbolData,
    list,
    dispatch
}) => {
    return (

       
        <div className="select-a-list">
                 <div className="custom-scroll-light">
                   <div className="custom-scroll">
                <div  id="contentScroll" >
                            {
                                list.length > 0 ?
                                list.map((rowslist, row) =>
                                    rowslist.isVisible &&
                                    <div key={row}>
                                        <TreeHeader
                                            filtersymbolData={filtersymbolData}
                                            list={list}
                                            rowslist={rowslist}
                                            dispatch={dispatch}
                                        />
                                    </div>
                                )
                                :
                                <div className="x-large-normal no-news-found btn-center-news">
                                    <div className="news-loading-spinner"></div>
                                </div>
                            }
                        </div>
                   </div>
                <ScrollBar scrollId="contentScroll" vScroll={true} />
            </div>
     
         </div>
     
    );
}

export default SymbolData;
