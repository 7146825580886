import React from "react";

import { setSelectedStream } from "../../../../Actions/NewsActions";

const NewsSources = ({
  selectedStreamer,
  newsType,
  newsTypeParent,
  newsSource,
  dispatch
}) => {
  const handleSelectNewsSource = event => {
    if (event.target.checked) {
      selectedStreamer.Sources.map(rowStreamSource => {
        if (
          rowStreamSource.newsType === newsType &&
          rowStreamSource.parents === newsTypeParent &&
          rowStreamSource.sourceName === newsSource.sourceName
        ) {
          rowStreamSource.isSelect = true;
        }
      });
    } else {
      selectedStreamer.Sources.map(rowStreamSource => {
        if (
          rowStreamSource.newsType === newsType &&
          rowStreamSource.parents === newsTypeParent &&
          rowStreamSource.sourceName === newsSource.sourceName
        ) {
          rowStreamSource.isSelect = false;
        }
      });
    }
    selectedStreamer.FilterFlag = true;
    dispatch(setSelectedStream(selectedStreamer));
  };

  return (
    <div className="mark-field" data-test-id="main-div">
      <input
        className="custom-check"
        id={newsTypeParent + newsSource.sourceName + "-selected"}
        type="checkbox"
        checked={newsSource.isSelect}
        onChange={handleSelectNewsSource}
      />

      <label
        className="txt-head"
        htmlFor={newsTypeParent + newsSource.sourceName + "-selected"}
      >
        <span />
        {newsSource.sourceName}
      </label>
    </div>
  );
};

export default NewsSources;
