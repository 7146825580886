import React from 'react';

import { FormControl } from "react-bootstrap";
import FilterSymbols from './FilterSymbols.jsx';
import FilterConditional from './FilterConditional.jsx';
import FilterKeyWords from './FilterKeyWords.jsx';



const FilterData = ({
    filtersymbolData,
    selectedStreamer,
    dispatch
}) => {

    return (
        <div className="news-region" data-test-id="main-div">
            <FilterSymbols
                filtersymbolData={filtersymbolData}
                dispatch={dispatch}
            />
            <FilterConditional
                filtersymbolData={filtersymbolData}
                dispatch={dispatch}
            />

            <FilterKeyWords
                filtersymbolData={filtersymbolData}
                dispatch={dispatch}
            />
            <div className="clearfix"></div>
        </div>
    );
}

export default FilterData;


