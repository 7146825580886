import React from "react";

import FlexibleModal from "../../../../RayCustomControls/FlexibleModal.jsx";
import FilterPage from "./FilterPage.jsx";

const FilterDialog = (
    {
        streamerList,
        selectedStreamer,
        newsState,
        sourceData,
        filtersymbolData,
        listData,
        x_parent,
        y_parent,   
        filterListValidation,     
        dispatch
    }) => {
        
    const getXPosition = () => {       
        let x = 0;        
        x = document.body.scrollLeft + sourceData.clientX;
        return x;
    }

    const getYPosition = () => {
        let y = 0; 
        y = document.body.scrollTop + sourceData.clientY;
        return y;
    }    
    return (
        <FlexibleModal left={getXPosition()} top={getYPosition()} open={true} isDefaultDragEnabled = {false}
        draggable="true" className="modal-popup modal-news-filter" data-test-id="main-div">
            <FilterPage
                streamerList={streamerList}
                selectedStreamer={selectedStreamer}
                newsState={newsState}
                filtersymbolData={filtersymbolData}
                listData={listData}
                filterListValidation={filterListValidation}
                sourceData={sourceData}
                dispatch={dispatch}
            />
        </FlexibleModal>
    );
}

export default FilterDialog;
