import React from 'react';
import LocalizationStore from "LocalizationStore";
import NameDialog from '../NewsName/NameDialog.jsx';
import { setSource, setDeleteNews, setSelectedStream, setSymbol, saveStreamList, fetchStreamerNewsSources, setList, checkChildNodeId, setDragStream } from '../../../../Actions/NewsActions';
import BaseServiceApi from 'BaseServiceApi';

const ListType = BaseServiceApi.rayData["ListType"];
const Header = ({ streamerList, listData, streamer, selectedStreamer, isnameChanged, dispatch }) => {

    const stringStreamer = JSON.stringify(streamer);
    const selectedStream = JSON.parse(stringStreamer);
    const filterStrDisplayWidth = 70;    
    const removeStream = (event) => {
        const sourceState = {
            showSource: false,
            showStreamName: false,
            showFilter: false
        }

        dispatch(setSource(sourceState));

        // const deleteNewsState = {
        //     showDeleteNews: true
        // }
        dispatch(setDeleteNews(true));
        dispatch(setSelectedStream(selectedStream));
    }

    const updateShowSource = (event) => {
        const sourceState = {
            showSource: true,
            showStreamName: false,
            showFilter: false
        }

        dispatch(setSource(sourceState));
        dispatch(fetchStreamerNewsSources(selectedStream));
        //dispatch(setSelectedStream(selectedStream));
    }

    const updateStreamName = (event) => {
        event.stopPropagation();
        dispatch(setDragStream(null));   
        streamer.enableNamePopup = true;
        // const sourceState = {
        //     showSource: false,
        //     showStreamName: true,
        //     showFilter: false
        // }
        dispatch(saveStreamList(streamerList));
        // dispatch(setSource(sourceState));
        dispatch(setSelectedStream(selectedStream));
    }

    const updateShowFilter = (event) => {
        for (let itm of listData) {
            let result = dispatch(checkChildNodeId(itm, streamer.SelectedFilterListId));
            if(result) {
                itm.expandNode = true;
            }
            else {
                itm.expandNode = false;
            }
        }
        dispatch(setList(listData));

        let flag = false;
        const sourceState = {
            showSource: false,
            showStreamName: false,
            showFilter: true,
            clientX: event.clientX,
            clientY: event.clientY
        }

        dispatch(setSource(sourceState));
        dispatch(setSelectedStream(selectedStream));

        const filterdata = selectedStream.NewsStreamerFilter;
        const selSymbol = filterdata.newsStreamerListSelect;
        let newSymbol = [];
        if(!selectedStream.FilterListType && selSymbol && selSymbol.listname!== ""){
            selectedStream.FilterListType = ListType.LIST_List;
        }
        newSymbol.push({
            listname: selSymbol.listname,
            listId: selSymbol.listId,
            listType: selectedStream.FilterListType,
            selectedFilterListId: selectedStream.SelectedFilterListId
        })
        const symbolInitState = {
            showSymbol: false,
            data: undefined,
            selectedSymbol: selectedStream.FilterListType === ListType.LIST_List ? newSymbol : undefined,
            searchSymbol: filterdata.symbols,
            phrasesKeywords: filterdata.keywords,
            IsAndOperatorChecked: filterdata.operator
        }
        dispatch(setSymbol(symbolInitState));
    }

    const updateFilterContextWidth = (node) => {
        if (node !== null && (streamer.FilterContextWidth + filterStrDisplayWidth)!== node.offsetWidth) {
            streamer.FilterContextWidth = node.offsetWidth - filterStrDisplayWidth;
        }
    }

    const onMouseDown = (event) => {
        dispatch(setDragStream(selectedStream));       
    }

    const deleteButtonVisible= streamerList.length  > 1;
    let selectedFilterDislayStr = '';
    if(streamer.SelectedFilterDislayStr){
        selectedFilterDislayStr = LocalizationStore.getTranslatedData("TreeExplorer_LST_" + streamer.SelectedFilterDislayStr.replace(/ /ig,""),streamer.SelectedFilterDislayStr);
    }
    return (
        <div className="list-group-item fix-head" style={{ top: '0px' }}>
            <div>
                <div className="close-action" onMouseDown={onMouseDown}>
                    <a
                        className="heading-list-group-item-streamName"
                        target="_blank"
                        onClick={updateStreamName}>
                        {streamer.StreamName}
                    </a>
                    {
                        deleteButtonVisible &&
                        <button type="button" className="btn-close" onClick={removeStream} data-effect="fadeOut">
                            <span className="icn-closing"></span>
                        </button>
                    }
                </div>
            </div>
            <div >
                <a
                    className="heading-list-group-item-selected-sources sb-link-news"
                    target="_blank"
                    onClick={updateShowSource}>
                    {LocalizationStore.getTranslatedData("news_name_source","Sources:")}
                </a><span className="heading-list-group-item-selected-sources-details small-bold">{LocalizationStore.getTranslatedData("news_"+streamer.SelectedSourcesStr.replace(/ /ig,""),streamer.SelectedSourcesStr)}</span>
            </div>
            <div ref={ node => { updateFilterContextWidth(node) }}>
                <a
                    className="heading-list-group-item-filter sb-link-news"
                    target="_blank"
                    onClick={updateShowFilter}>
                    {LocalizationStore.getTranslatedData("news_name_filter", "Filters:")}
                </a><span className="heading-list-group-item-filter-details" style={{width: `${selectedStream.FilterContextWidth}px`}}>{selectedFilterDislayStr}</span>
            </div>
            {

                streamer && streamer.enableNamePopup &&
                <NameDialog
                    streamerList={streamerList}
                    selectedStreamer={selectedStreamer}
                    streamer={streamer}    
                    isnameChanged={isnameChanged}
                    dispatch={dispatch}
                    data-test-id="name-dialog"
                />
            }
        </div>

    );
}

export default Header;
