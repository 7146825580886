import React from "react";
import { pure } from "recompose";
import { filter } from "underscore";
import { fetchNews, setSource } from "../../../../Actions/NewsActions";
import {
  newsActions,
  newsConstants
} from "../../../../Constants/NewsConstants";
import StringUtil from "StringUtil";
import DateHelper from "DateHelper";
import LocalizationStore from 'LocalizationStore';
import moment from "moment";
import FormatterUtil from "FormatterUtil";
import UserInfoUtil from "UserInfoUtil";
import AdobeUtil from "../../../../Utils/AdobeUtil";
import AdobeConstants from "../../../../Constants/AdobeConstants";

const TabData = ({
  streamerListLength,
  newsData,
  topPosition,
  streamer,
  dispatch
}) => {
  const top = topPosition * 100;
  const source = filter(
    streamer.SourcesAll, 
    rowSource => rowSource.sourceId === newsData.sourceid
  );
  let sourceText = "";
  if (!StringUtil.isEmpty(source)) {
    sourceText =
      source[0].parents === source[0].sourceName
        ? `${LocalizationStore.getTranslatedData("news_"+source[0].sourceName.replace(/ /ig, ""),source[0].sourceName)}`
        : `${LocalizationStore.getTranslatedData("news_"+source[0].parents.replace(/ /ig, ""),source[0].parents)}`;
  }
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    if(UserInfoUtil.hasChinaEntitlement()) {
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
    } else {
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      strTime = hours + ":" + minutes + " " + ampm;
    }
    return strTime;
  }
  const dateValue = new Date(Number(newsData.newstime));
  // const dateText = `${dateValue.toDateString()}`;
  const zoneText = DateHelper.getTZoneAbbreviation(dateValue);
  const dateText = DateHelper.getMonthDay(dateValue);
  const year = dateValue.getFullYear();
  const month = dateValue.getMonth()+1;
  const date = dateValue.getDate();
  const week = dateText.split(",")[0];
  const weekTrans = LocalizationStore.getTranslatedData("cal_"+ week.toLowerCase(),week);
  const time  = `${weekTrans} ${LocalizationStore.getTranslatedData("sb_date", "{0} {1}", LocalizationStore.getTranslatedData("cal_Num_"+ month, month), year)}${LocalizationStore.getTranslatedData("sb_day","{0}",date)}`;
  const dateTimeText = `${UserInfoUtil.hasChinaEntitlement()? time : dateText} ${formatAMPM(dateValue)} ${LocalizationStore.getTranslatedData("news_date",zoneText)}`;
  const dateSourceText = `${dateTimeText} - ${sourceText}`;
  const sourceTitle = sourceText;
  const addNewSymbolStream = symbol => {
    let isMax = streamerListLength === newsConstants.MAX;
    const sourceState = {
      showSource: false,
      showStreamName: false,
      showFilter: false,
      showMax: isMax
    };
    sourceState.showMax
      ? dispatch(setSource(sourceState))
      : dispatch(fetchNews(null, null, newsActions.ADD, symbol));
  };

  let symarr = "";
  if (!StringUtil.isEmpty(newsData.symbols)) {
    symarr = newsData.symbols.split(" ");
    symarr = symarr.reverse();
  }

  const handleArticleClick = (source, title) => {    
    AdobeUtil.DTMTrack({
      articleTitle: title,
      newsArticleSource: source
    },
      AdobeConstants.NEWS_ARTICLE_CLICK);
  }

  return (
    <div
      className="list-group-item list-render"
      data-test-id="main-div"

    >
      <a className="medium-normal sb-link-news" target="_blank" href={newsData.url} onClick={() => handleArticleClick(sourceTitle, newsData.title)}>
        {newsData.title}
      </a>
      <div className="xx-small-normal details-tab-data">{dateSourceText}</div>
      <div className="btn-symbol-add">
        {newsData.symbols &&
          symarr.map((item, i) => (
            <button
              type="button"
              className="xx-small-normal btn btn-xs btn-default  mart5"
              data-effect="fadeOut"
              key={i}
              symbol={item}
              onClick={() => addNewSymbolStream(item)}
            >
              {item}{" "}
            </button>
          ))}
      </div>
    </div>
  );
};

export default pure(TabData);
