import React from "react";
import { connect } from 'react-redux';
import { fetchNews, setRoute, setJob, fetchListData, setSelectedStream, setDragStream, updateStreamList } from '../../../Actions/NewsActions';
import { newsActions } from '../../../Constants/NewsConstants';
import NewsPage from './NewsTabs/NewsPage.jsx';
import AdobeUtil from "../../../Utils/AdobeUtil";
import AdobeConstants from "../../../Constants/AdobeConstants";
import textWidth from "text-width";
import LocalizationStore from 'LocalizationStore';
import { dispatch } from "../../../Redux/dispatch";

class NavNewsManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NewsData: this.props.NewsData
        }
        this.newStreamerList = [];
        this.clentRect = null;
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragMove = this.handleDragMove.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.OnWindowSizeChange = this.OnWindowSizeChange.bind(this);
        this.FilterStringHander = this.FilterStringHander.bind(this);
    }

    FilterStringHander (streamer) {
        let keywords = [];
        let selectTextLength = streamer.FilterContextWidth;
        let result = streamer.SelectedFilterDislayStr;
        if(streamer.FilterDislayStrWidth !== streamer.FilterContextWidth){
           streamer.FilterDislayStrWidth = streamer.FilterContextWidth;
           let symbolStr = streamer.NewsStreamerFilter.symbols.replace(/,/g, " ");
           symbolStr = symbolStr.replace(/  /g," ");
           let symbols = symbolStr.split(" ");
           if(symbols && symbols.length > 0){
               symbols.forEach((symbol)=>{
                   if(symbol){
                       keywords.push(symbol);
                   }
               });
           }
           let filterStr = streamer.NewsStreamerFilter.keywords;
           let array = filterStr.split('"');
           if(array && array.length > 0){
               array.forEach((str) =>{ 
                   let index = filterStr.indexOf(str); 
                   if(index > -1){
                       if (index > 0 && index + str.length < filterStr.length){
                           if (filterStr[index - 1] === '\"' && filterStr[index + str.length] === '\"'){
                               keywords.push(str);
                               filterStr = filterStr.slice(str.length + 2, filterStr.length);
                           }
                       }
                       else{
                           if (str.split(' ')){
                               str.split(' ').forEach((s)=>{
                                   if (s.split(',')){
                                       s.split(',').forEach(x => {
                                           if(x){
                                               keywords.push(x);
                                           }
                                       });
                                   }
                               })
                               filterStr = filterStr.slice(str.length , filterStr.length);
                           }
                       }
                   }
               });
           }
           
           let selectedListName = streamer.NewsStreamerFilter.newsStreamerListSelect.listname;
           selectedListName = LocalizationStore.getTranslatedData("TreeExplorer_LST_" + selectedListName.replace(/ /ig,""), selectedListName);
           if(selectedListName){
               keywords.push(selectedListName);
           }
           let hiddenItems = "";
           let hiddenCount = 0;
           result = keywords.length > 0 ? keywords[0] : LocalizationStore.getTranslatedData("sb_none", 'None'); 
           for (let num = 1; num < keywords.length; num++) { 
               hiddenCount = keywords.length - num;
               hiddenItems = hiddenCount > 0 ? ` +${hiddenCount} more` : "";
               let keyStr = keywords[num].trim();
               let keywordStr = `${result}, ${keyStr}${hiddenItems}`;
               let filterStrWidth = textWidth(keywordStr, { family: "calibri",
                                                            size: 14
                                                          });
               if(filterStrWidth < selectTextLength){
                   result = result.length === 0 ? keyStr : `${result}, ${keyStr}`;
               }
               else {
                   result =`${result}${hiddenItems}`;
                   break;
               }
           }
        }
        
        return result;
    }

    OnWindowSizeChange () {
        if(this.state && this.state.NewsData && this.state.NewsData.streamerList){
            this.state.NewsData.streamerList.forEach((streamer) =>{ 
                streamer.SelectedFilterDislayStr = this.FilterStringHander(streamer);
                this.props.dispatch(
                    setSelectedStream(streamer)
                );
            });
        }
    }


    UNSAFE_componentWillMount() {
        this.props.dispatch(setRoute('news'));
        this.props.dispatch(fetchListData());

        AdobeUtil.DTMTrack({
            pageName: "Panaray : News",
            siteSection: "Panaray : News"
        },
          AdobeConstants.NEWS_LOAD);

        if (this.state.NewsData.streamerList.length === 0) {
            this.props.dispatch(
                fetchNews(
                    this.state.NewsData.streamerList,
                    this.state.NewsData.newsState,
                    newsActions.CREATE
                )
            );
        }
        else {
            this.props.dispatch(setJob(true, newsActions.ADD));
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.NewsData !== nextProps.NewsData) {
            this.setState({ NewsData: nextProps.NewsData })
        }
    }

    componentDidMount () {
        window.addEventListener("resize", this.OnWindowSizeChange);
        window.addEventListener("dragstart", this.handleDragStart, false);
        window.addEventListener("drag", this.handleDragMove, false);
        window.addEventListener("dragend", this.handleDragEnd, false);
    }

    componentWillUnmount() {
        this.props.dispatch(setRoute(''));
        window.removeEventListener("resize", this.OnWindowSizeChange);
        window.removeEventListener("dragstart", this.handleDragStart, false);
        window.removeEventListener("drag", this.handleDragMove, false);
        window.removeEventListener("dragend", this.handleDragEnd, false);
    }

    handleDragStart(e) {
        const { dragStreamer } = this.state.NewsData;
        if (dragStreamer != undefined && dragStreamer != null) {
            this.clentRect = this.clentRect == null ? e.srcElement.getBoundingClientRect() : this.clentRect;
            const element = e.srcElement;
            e.dataTransfer.setDragImage(element, e.clientX - this.clentRect.left, e.clientY - this.clentRect.top);
        }
    }

    handleDragMove(e) {
        const { dragStreamer, streamerList } = this.state.NewsData;
        if (dragStreamer != undefined && dragStreamer != null) {
            this.newStreamerList = JSON.parse(JSON.stringify(streamerList));
            let len = this.newStreamerList.length;
            let cindex = -1;
            while (len--) {
                if (this.newStreamerList[len].LayoutOrder === dragStreamer.LayoutOrder)
                    cindex = len;
            }
            if (cindex < 0) return;
            this.clentRect = this.clentRect == null ? e.srcElement.getBoundingClientRect() : this.clentRect;
            if(e.clientX <= 0 || e.clientY <= 0) return;
            const tindex = cindex + Math.floor((e.clientX - this.clentRect.left) / this.clentRect.width)
            if (tindex < cindex) {
                this.newStreamerList.splice(tindex, 0, this.newStreamerList[cindex]);
                this.newStreamerList.splice(cindex + 1, 1)
            } else {
                this.newStreamerList.splice(tindex + 1, 0, this.newStreamerList[cindex]);
                this.newStreamerList.splice(cindex, 1)
            }
        }
    }

    handleDragEnd(e) {
        const { dragStreamer } = this.state.NewsData;
        if (dragStreamer && this.newStreamerList.length > 0) {
            let scrollbarTopList = [];
            this.newStreamerList.map((item, i) => {
                let preScrollBar = document.getElementById(item.LayoutOrder);
                if (preScrollBar && preScrollBar.scrollTop != undefined){
                    scrollbarTopList.push({
                        preLayoutOrder: item.LayoutOrder,
                        newLayoutOrder: i,
                        scrollbarTop: preScrollBar.scrollTop
                    })
                }
                item.LayoutOrder = i;
                return item;
            });
            dispatch(setDragStream(null));
            dispatch(updateStreamList(this.newStreamerList));
            this.newStreamerList = [];
            this.clentRect = null;
            
            scrollbarTopList.map((item, i) => {
                let curScrollBar = document.getElementById(item.newLayoutOrder);
                if (curScrollBar && curScrollBar.scrollTop != undefined){
                    curScrollBar.scrollTop = item.scrollbarTop; 
                }
            });
        }
    }

    render() {
        const {
            newsSources,
            streamer,
            newsList,
            newsState,
            streamerList,
            dragStreamer,
            sourceData,
            filtersymbolData,
            scrollflag,
            listData,
            deleteNewsData,
            isnameChanged,
            getFilterList,
            filterListValidation
        } = this.state.NewsData;
        streamerList.forEach((streamer) =>{ 
            streamer.SelectedFilterDislayStr = this.FilterStringHander(streamer);
        });
        return (           
            <NewsPage
                streamerList={streamerList}
                dragStreamer={dragStreamer}
                streamer={streamer}
                newsState={newsState}
                sourceData={sourceData}
                filtersymbolData={filtersymbolData}
                listData={listData.list}
                deleteNewsData={deleteNewsData}
                scrollflag={scrollflag}
                dispatch={this.props.dispatch}
                isnameChanged={isnameChanged}
                getFilterList={getFilterList}
                filterListValidation={filterListValidation}
            />
        )
    }

}
function mapStateToProps(state) {
    return {
        NewsData: state.news
    }
}

export default connect(mapStateToProps)(NavNewsManager);
