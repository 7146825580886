import React from "react";
import BaseServiceApi from 'BaseServiceApi';
import TreeLIstNode from './TreeListNode';
import { reject, findIndex, filter } from "underscore";
import StringUtil from "StringUtil";

let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
let ListType = BaseServiceApi.rayData["ListType"];
let ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

const TreeHeader = ({
    filtersymbolData,
    list,
    rowslist,
    dispatch
}) => {
    let collapsed = !rowslist.expandNode;
    let IsVisible = rowslist.isVisible;
    let containerClassName = (rowslist.listType === ListType.FOLDER_List ? 'tree-view_children_folder folder-level-1' : 'tree-view_children folder-level-2');
      let ClassName1 = (rowslist.listType === ListType.FOLDER_List ? 'tree-view' : 'tree-view childhover');

    let isChFolder = rowslist.childNodes.length > 0 ? true : false;

    let childNodes = rowslist.childNodes;
    if (IsVisible) {
        if (collapsed) {
            containerClassName += ' tree-view_children-collapsed folder-level-3';
        }
        
        return (
            <div className={ClassName1} id={rowslist.nodeId.low} >
                {
                    <div>
                        <TreeLIstNode
                            filtersymbolData={filtersymbolData}
                            list={list}
                            rowslist={rowslist}
                            containerClassName={containerClassName}
                            dispatch={dispatch}
                        />
                        <div className={containerClassName} style={{float:'left', width:'100%'}}>
                            {
                                isChFolder &&
                                childNodes.map((rowslist, row) =>
                                    rowslist.isVisible &&
                                    <TreeHeader
                                        key={row}
                                        filtersymbolData={filtersymbolData}
                                        list={list}
                                        rowslist={rowslist}
                                        dispatch={dispatch}
                                    />

                                )
                            }
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default TreeHeader;