import React from 'react';

import NewsType from './NewsType.jsx';

const SourceData = ({
    selectedStreamer,
    dispatch
}) => {
    const newsTypes = selectedStreamer &&
        [...new Set(selectedStreamer.Sources.map(item => item.newsType))];

    return (
        <div className="select-source" data-test-id="main-div">
            {
                newsTypes && newsTypes.length > 0 &&
                newsTypes.map((newsType, key) =>
                    <NewsType
                        selectedStreamer={selectedStreamer}
                        newsType={newsType}
                        dispatch={dispatch}
                        key={key}
                    />
                )
            }
        </div>
    );
}

export default SourceData;
