import React from 'react';
import { newsActions } from '../../../../Constants/NewsConstants';
import { fetchNews } from '../../../../Actions/NewsActions';
import { pure } from 'recompose';
import LocalizationStore from "LocalizationStore";

const TabAdd = ({ isLoading, dispatch }) => {

    const addNewStream = () => {
        dispatch(fetchNews(null, null, newsActions.ADD));
    }   

    return (
        <div className="flexbox-parent minWidth300" data-test-id="tab-add">
            <div className="flexbox-item fill-area content flexbox-item-grow">
                <div className="fill-area-content flexbox-item-grow">
                    <div className="infoContainer">
                        <div className="inner-infoContainer news-block">
                            <div className="newsInfo">
                                <div className="list-group list-group-wi-348">
                                    <div className="testing list-group-wi-348-testing">
                                        <button
                                            type="button"
                                            className="add-news-stream btn  btn-default small-bold  disable-drag btn-center-news"
                                            data-effect="fadeOut"
                                            onClick={addNewStream}
                                            disabled={isLoading}
                                        >
                                           +{LocalizationStore.getTranslatedData("news_add"," Add News Stream")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default pure(TabAdd);

