import React from "react";

import NamePage from "./NamePage.jsx";

const NameDialog = (
    {
        streamerList,
        selectedStreamer,  
        streamer,
        isnameChanged,
        dispatch
    }) => { 
    return (
        <div data-test-id="main-div" className="modal-popup modal-stream-name">
            <NamePage streamerList={streamerList} selectedStreamer={selectedStreamer} streamer={streamer} isnameChanged={isnameChanged} dispatch={dispatch} />
        </div>
    );
}

export default NameDialog;
