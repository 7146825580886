import React from "react";
import { Modal } from "react-bootstrap";

import { setSymbol, fetchNewsList, setSelectedStream, setSource, setListValidation, fetchListValidation  } from '../../../../Actions/NewsActions';
import { newsActions } from '../../../../Constants/NewsConstants';
import SymbolDialog from '../NewsFilters/Symbol/SymbolDialog.jsx';
import LocalizationStore from 'LocalizationStore';
import FilterData from './FilterData.jsx';
import textWidth from "text-width";
import FlexibleModal from "../../../../RayCustomControls/FlexibleModal";
const FilterPage = ({
    streamerList,
    selectedStreamer,
    newsState,
    filtersymbolData,
    listData,
    filterListValidation,
    sourceData,
    dispatch
}) => {
    const selectedLayoutOrder = selectedStreamer.LayoutOrder;
    const oldFilterData = streamerList[0].NewsStreamerFilter;
    const updateStream = (event) => {
        var code = event.keyCode || event.which;
        if ((code === 13) || (!code)) {
            const oldSelectedFilterStr = selectedStreamer.SelectedFilterStr;

            if (filtersymbolData) {
                loadfilterSymbolData(
                    selectedStreamer,
                    filtersymbolData)
            }
            // const filterFlag = oldSelectedFilterStr === selectedStreamer.SelectedFilterStr
            //      && selectedStreamer.IsAndOperatorChecked === filtersymbolData.IsAndOperatorChecked
            //      ? false : true;
            const filterFlag = (oldSelectedFilterStr === selectedStreamer.SelectedFilterStr && selectedStreamer.IsAndOperatorChecked === filtersymbolData.IsAndOperatorChecked && 
                oldFilterData.symbols === filtersymbolData.searchSymbol &&
                oldFilterData.keywords === filtersymbolData.phrasesKeywords)?false:true

            if (filterFlag) {
                selectedStreamer.MessageVisibility = true;
                selectedStreamer.IsAndOperatorChecked = filtersymbolData.IsAndOperatorChecked;
                streamerList.splice(selectedLayoutOrder, 1, selectedStreamer);
                dispatch(fetchNewsList(
                    streamerList,
                    newsState,
                    selectedLayoutOrder,
                    newsActions.UPDATE)
                );
            }
            const symbolData = {
                showSymbol: false,
                data: undefined,
                selectedSymbol: undefined,
                searchSymbol: "",
                phrasesKeywords: "",
                IsAndOperatorChecked: false
            }
            const sourceState = {
                showSource: false,
                showStreamName: false,
                showFilter: false
            }

            dispatch(setSource(sourceState));
            dispatch(setSelectedStream(null));
            dispatch(setSymbol(symbolData));
        }

    }

    const updateShowSource = () => {
        const symbolData = {
            showSymbol: false,
            data: undefined,
            selectedSymbol: undefined,
            searchSymbol: "",
            phrasesKeywords: "",
            IsAndOperatorChecked: false
        }
        const sourceState = {
            showSource: false,
            showStreamName: false,
            showFilter: false
        }

        dispatch(setSource(sourceState));
        dispatch(setSelectedStream(null));
        dispatch(setSymbol(symbolData));

    }

    const validateSymbolData = (filtersymbol, fltsymDt, filtertype) => {
        let commacheck = filtersymbol.search(',');
        if (commacheck < 0) {
            let filtration = (filtertype == 'keyword') ? filtersymbol.match(/(?:[^\s"]+|"[^"]*")+/g) : filtersymbol.replace(/\s+/g, ' ').split(" ");
            let filtertext = "";
            let i = "";
            let commaseparator = "";
            if (filtration) {
                let commastring = filtration.length - 1;
                for (i = 0; i < filtration.length; i++) {
                    (i == commastring) ? commaseparator = "" : commaseparator = ", ";
                    filtertext += filtration[i] + commaseparator;
                }
            }
            (filtertype == 'symbol') ? fltsymDt.searchSymbol = filtertext : fltsymDt.phrasesKeywords = filtertext;
        } else {
            (filtertype == 'symbol') ? fltsymDt.searchSymbol = filtersymbol : fltsymDt.phrasesKeywords = filtersymbol;
        }
    }

    const FilterStringHander = (streamer) => {
        let keywords = [];
        let selectTextLength = streamer.FilterContextWidth;
        let symbolStr = streamer.NewsStreamerFilter.symbols.replace(/,/g, " ");
        symbolStr = symbolStr.replace(/  /g," ");
        let symbols = symbolStr.split(" ");
        if(symbols && symbols.length > 0) {
            symbols.forEach((symbol)=>{
                if(symbol) {
                    keywords.push(symbol);
                } 
            });
        }

        let filterStr = streamer.NewsStreamerFilter.keywords;
        let array = filterStr.split('"');
        if(array && array.length > 0){
            array.forEach((str) =>{ 
                let index = filterStr.indexOf(str); 
                if(index > -1){
                    if (index > 0 && index + str.length < filterStr.length){
                        if (filterStr[index - 1] === '\"' && filterStr[index + str.length] === '\"'){
                            keywords.push(str);
                            filterStr = filterStr.slice(str.length + 2, filterStr.length);
                        }
                    }
                    else{
                        if (str.split(' ')){
                            str.split(' ').forEach((s)=>{
                                if (s.split(',')){
                                    s.split(',').forEach(x => {
                                        if(x && x!==""){
                                            keywords.push(x);
                                        }
                                    });
                                }
                            })
                            filterStr = filterStr.slice(str.length , filterStr.length);
                        }
                    }
                }
            });
        }
        let selectedListName = streamer.NewsStreamerFilter.newsStreamerListSelect.listname;
        if(selectedListName){
            keywords.push(selectedListName);
        }
        let hiddenItems = "";
        let hiddenCount = 0;
        
        let result = keywords.length > 0 ? keywords[0] : "";
        for (let num = 1; num < keywords.length; num++) { 
            hiddenCount = keywords.length - num;
            hiddenItems = hiddenCount > 0 ? ` +${hiddenCount} more` : "";
            let keyStr = keywords[num].trim();
            let keywordStr = `${result}, ${keyStr}${hiddenItems}`;
            let filterStrWidth = textWidth(keywordStr, { family: "calibri",
                                                         size: 14
                                                        });
            if(filterStrWidth < selectTextLength){
                result = result.length === 0 ? keyStr : `${result}, ${keyStr}`;
            }
            else {
                result =`${result}${hiddenItems}`;
                break;
            }
        }
        return result;
    }

    const loadfilterSymbolData = (selectedStreamer, fltsymDt) => {
        let isSelect = fltsymDt.selectedSymbol ? true : false;
        let strmfilter = selectedStreamer.NewsStreamerFilter;
        let fltselecter = strmfilter.newsStreamerListSelect;
        validateSymbolData(fltsymDt.searchSymbol, fltsymDt, 'symbol');
        validateSymbolData(fltsymDt.phrasesKeywords, fltsymDt, 'keyword');
        strmfilter.symbols = fltsymDt.searchSymbol;
        strmfilter.keywords = fltsymDt.phrasesKeywords;
        strmfilter.operator = fltsymDt.IsAndOperatorChecked;
        fltselecter.listId = isSelect ? fltsymDt.selectedSymbol[0].listId : 0;
        fltselecter.listname = isSelect ? LocalizationStore.getTranslatedData("TreeExplorer_LST_"+fltsymDt.selectedSymbol[0].listname.replace(/ /ig,""),fltsymDt.selectedSymbol[0].listname) : "";
        let filterword = isSelect && fltsymDt.searchSymbol && fltsymDt.phrasesKeywords
            ? (fltsymDt.searchSymbol + ',' + fltsymDt.phrasesKeywords + ',' + LocalizationStore.getTranslatedData("TreeExplorer_LST_"+fltsymDt.selectedSymbol[0].listname.replace(/ /ig,""),fltsymDt.selectedSymbol[0].listname))
            : isSelect && fltsymDt.searchSymbol
                ? (fltsymDt.searchSymbol + ',' + LocalizationStore.getTranslatedData("TreeExplorer_LST_"+fltsymDt.selectedSymbol[0].listname.replace(/ /ig,""),fltsymDt.selectedSymbol[0].listname))
                : isSelect && fltsymDt.phrasesKeywords
                    ? (fltsymDt.phrasesKeywords + ',' + LocalizationStore.getTranslatedData("TreeExplorer_LST_"+fltsymDt.selectedSymbol[0].listname.replace(/ /ig,""),fltsymDt.selectedSymbol[0].listname))
                    : (fltsymDt.searchSymbol && fltsymDt.phrasesKeywords)
                        ? (fltsymDt.searchSymbol + ',' + fltsymDt.phrasesKeywords)
                        : isSelect
                            ? LocalizationStore.getTranslatedData("TreeExplorer_LST_"+fltsymDt.selectedSymbol[0].listname.replace(/ /ig,""),fltsymDt.selectedSymbol[0].listname)
                            : fltsymDt.searchSymbol
                                ? fltsymDt.searchSymbol
                                : fltsymDt.phrasesKeywords
                                    ? fltsymDt.phrasesKeywords : LocalizationStore.getTranslatedData("sb_none", 'None');
        isSelect = false;
        selectedStreamer.SelectedFilterStr = filterword;
        if(filterword === LocalizationStore.getTranslatedData("sb_none", 'None')){
            selectedStreamer.SelectedFilterDislayStr = filterword;
        }else{
            selectedStreamer.SelectedFilterDislayStr = FilterStringHander(selectedStreamer);
        }
    }

    const handleLimitClose = (event) => {
        const resetValid = {
            filterListValidation:true
        }
        dispatch(setListValidation(resetValid))
    }
    
    const getXPosition = () => {       
        let x = 0;        
        x = document.body.scrollLeft + sourceData.clientX + 50;
        return x;
    }

    const getYPosition = () => {
        let y = 0; 
        y = document.body.scrollTop + sourceData.clientY + 70;
        return y;
    }    
    
    const limitAlert = 
    <FlexibleModal left={getXPosition()} top={getYPosition()} open={filterListValidation ? false : true} className="modal-popup" dialogClassName="news-filter-limit">
        <Modal.Header>
            <Modal.Title><span className="cap-header">{LocalizationStore.getTranslatedData("news_filter_limit","NEWS LIST FILTER LIMIT")}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="modal-material">
                <div className="row">
                    <div className="col-xs-12">
                        <p>{LocalizationStore.getTranslatedData("news_filter_limit_note","List filter can be set only for lists which have 1,000 symbols or less.")}</p>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
                <center><button type="button" className="btn btn-xs btn-default btn-secondary" onClick={handleLimitClose}> {LocalizationStore.getTranslatedData("ok", "OK")} </button></center>
        </Modal.Footer>
    </FlexibleModal>


    return (
        <div className="news-filter-block" data-test-id="main-div" onKeyPress={updateStream}>
            {limitAlert}
            {
                filtersymbolData.showSymbol && selectedStreamer &&
                <SymbolDialog data-test-id="SymbolDialog"
                    filtersymbolData={filtersymbolData}
                    listData={listData}
                    selectedStreamer={selectedStreamer}
                    x_parent={400}
                    y_parent={200}
                    dispatch={dispatch}
                />
            }
            <Modal.Header>
                <Modal.Title>
                    <div className="enable-drag">
                        <span className="cap-header">{LocalizationStore.getTranslatedData("news_filter", "FILTERS")}</span>
                        <button className="btn btn-xxs btn-default btn-secondary btn-close" type="button" data-effect="fadeOut" onClick={updateShowSource} ><span className="icn-close"></span></button>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-area">
                    <FilterData filtersymbolData={filtersymbolData} selectedStreamer={selectedStreamer} dispatch={dispatch} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn-box righter">
                    <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={updateShowSource}> {LocalizationStore.getTranslatedData("cancel", "CANCEL")} </button>
                    <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={updateStream}> {LocalizationStore.getTranslatedData("ok", "OK")} </button>
                </span>
            </Modal.Footer>
        </div>
    );
}

export default FilterPage;
