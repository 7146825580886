import React from "react";
import { Modal, ControlLabel, FormGroup, Form, Table } from "react-bootstrap";

import { setSymbol , setSelectedStream, fetchListValidation} from '../../../../../Actions/NewsActions';
import { newsActions } from '../../../../../Constants/NewsConstants';

import LocalizationStore from 'LocalizationStore';
import SymbolData from './SymbolData';

const SymbolPage = ({
    filtersymbolData,
    listData,
    selectedStreamer,
    dispatch
}) => {
    const updateStream = (event) => {
        const fsymboldata = {
            showSymbol: false,
            data: filtersymbolData.data,
            selectedSymbol: filtersymbolData.tempSelectedSymbol,
            tempSelectedSymbol: undefined,
            IsAndOperatorChecked: filtersymbolData.IsAndOperatorChecked,
            searchSymbol: filtersymbolData.searchSymbol,
            phrasesKeywords: filtersymbolData.phrasesKeywords,
        }
        dispatch(setSymbol(fsymboldata));
        selectedStreamer.FilterListType = filtersymbolData.tempSelectedSymbol[0].listType;
        selectedStreamer.SelectedFilterListId = filtersymbolData.tempSelectedSymbol[0].selectedFilterListId;
        dispatch(setSelectedStream(selectedStreamer));
    }

    const updateShowSource = (event) => {
        const fsymbol = {
            showSymbol: false,
            data: filtersymbolData.data,
            selectedSymbol: filtersymbolData.selectedSymbol,
            tempSelectedSymbol: undefined,
            IsAndOperatorChecked: filtersymbolData.IsAndOperatorChecked,
            searchSymbol: filtersymbolData.searchSymbol,
            phrasesKeywords: filtersymbolData.phrasesKeywords
        }
        dispatch(setSymbol(fsymbol));
    }
    const ClearSelectedSymbol = (event) => {
        const fsymbol = {
            showSymbol: true,
            data: filtersymbolData.data,
            selectedSymbol: filtersymbolData.selectedSymbol,
            tempSelectedSymbol: undefined,
            IsAndOperatorChecked: filtersymbolData.IsAndOperatorChecked,
            searchSymbol: filtersymbolData.searchSymbol,
            phrasesKeywords: filtersymbolData.phrasesKeywords
        }
        dispatch(setSymbol(fsymbol));
    }
    
    const onSave = (event) => {
        if(filtersymbolData.tempSelectedSymbol) {
            dispatch(fetchListValidation(filtersymbolData.tempSelectedSymbol[0].listId, filtersymbolData, selectedStreamer))
        } else {
            const fsymbol = {
                showSymbol: false,
                data: filtersymbolData.data,
                selectedSymbol: undefined,
                IsAndOperatorChecked: filtersymbolData.IsAndOperatorChecked,
                searchSymbol: filtersymbolData.searchSymbol,
                phrasesKeywords: filtersymbolData.phrasesKeywords,
            }
            dispatch(setSymbol(fsymbol));
        }
    }
    
    return (
        <div className="modal-content" data-test-id="main-div">
            {/*<Modal.Header className="medium-bold">
                <Modal.Title className="medium-bold">
                    <div className="enable-drag">
                    
                        <button
                            type="button"
                            className="pull-right btn btn-xxs btn-default btn-secondary mart5 btn-close"
                            data-effect="fadeOut"
                            onClick={updateShowSource}
                        >
                            <span className="icn-close"></span>
                        </button>
                    </div>
                </Modal.Title>
            </Modal.Header>*/}
            <Modal.Body>

                <div className="modal-area">
                    <p>
                        {LocalizationStore.getTranslatedData("news_filterList","Select a list to restrict news stories to  symbols in a list")}
                    </p>
                    <div className="select-symbol">
                        {
                            <div className="select-symbol-hold">
                                <span className="selected-symbol-head">
                                    {LocalizationStore.getTranslatedData("news_selected","Selected list:")}
                                </span>
                                {
                                    filtersymbolData.tempSelectedSymbol != undefined && filtersymbolData.tempSelectedSymbol.length > 0
                                        ?
                                        <div className="selected-symbol">
                                            <span className="selected-list" target="_blank">
                                                {LocalizationStore.getTranslatedData("TreeExplorer_LST_"+ filtersymbolData.tempSelectedSymbol[0].listname.replace(/ /ig,""), filtersymbolData.tempSelectedSymbol[0].listname)}
                                            </span>
                                            <span className="icn-close"  onClick={ClearSelectedSymbol}></span>
                                        </div>
                                        :
                                        <span className="filtered-list none" target="_blank">
                                            {LocalizationStore.getTranslatedData("sb_none","None")}
                                        </span>
                                }
                            </div>
                        }
                    </div>
                    {
                        <SymbolData
                            filtersymbolData={filtersymbolData}
                            list={listData}
                            dispatch={dispatch}
                        />
                    }
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn-box center">
                    <button
                        type="button"
                        className="btn btn-xs btn-default btn-secondary"
                        data-effect="fadeOut"
                        onClick={updateShowSource}
                    >
                        {LocalizationStore.getTranslatedData("cancel","CANCEL")}
                    </button>
                    <button
                        type="button"
                        className="btn btn-xs btn-default btn-secondary"
                        data-effect="fadeOut"
                        onClick={onSave}
                    >
                        {LocalizationStore.getTranslatedData("save","SAVE")}
                    </button>
                </span>
            </Modal.Footer>
        </div>
    );
}

export default SymbolPage;
