import React from "react";
import { FormControl } from "react-bootstrap";
import { setSymbol } from '../../../../Actions/NewsActions';
import { FetchFilterList } from '../../../../Actions/NewsActions';
import LocalizationStore from 'LocalizationStore';
import BaseServiceApi from 'BaseServiceApi';

const ListType = BaseServiceApi.rayData["ListType"];
const FilterSymbolList = ({
    filtersymbolData,
    dispatch
}) => {
    const updateShowSymbol = (event) => {
        dispatch(FetchFilterList(filtersymbolData));

    }
    const ClearSelectedSymbol = (event) => {
        const fsymbol = {
            showSymbol: false,
            data: filtersymbolData.data,
            selectedSymbol: undefined,
            IsAndOperatorChecked: filtersymbolData.IsAndOperatorChecked,
            searchSymbol: filtersymbolData.searchSymbol,
            phrasesKeywords: filtersymbolData.phrasesKeywords,
        }
        dispatch(setSymbol(fsymbol));
    }
    return (
        <div className="select-symbol" data-test-id="main-div">
            {
                <div className="select-symbol-hold">

                    {
                       filtersymbolData.selectedSymbol && filtersymbolData.selectedSymbol[0].listType && filtersymbolData.selectedSymbol[0].listType === ListType.LIST_List && filtersymbolData.selectedSymbol[0].listId != 0
                            ?
                            <div className="selected-symbol">
                                <span
                                    className="selected-symbol-head"
                                >
                                    {LocalizationStore.getTranslatedData("news_listfilter","List Filter:")}
                                    </span>
                                <a
                                    className="selected-list"
                                    target="_blank"
                                    onClick={updateShowSymbol}
                                >
                                     {LocalizationStore.getTranslatedData("TreeExplorer_LST_"+ filtersymbolData.selectedSymbol[0].listname.replace(/ /ig,""), filtersymbolData.selectedSymbol[0].listname)}
                                </a>
                               
                                 <span className="icn-close" onClick={ClearSelectedSymbol}></span>
                            </div>
                            :
                            <a
                                className="filtered-list"
                                target="_blank"
                                onClick={updateShowSymbol}
                            >
                                {LocalizationStore.getTranslatedData("news_for","Select Symbols from a list")}
                            </a>
                    }

                </div>
            }
        </div>
    )
}
export default FilterSymbolList;






