import React from 'react';
import ErrorBoundary from "ErrorBoundary";
import TabsPage from './TabsPage.jsx';
import TabAdd from './TabAdd.jsx';
import EmptyTab from './EmptyTab.jsx';
import SourceDialog from '../NewsSources/SourceDialog.jsx';
import NameDialog from '../NewsName/NameDialog.jsx';
import FilterDialog from '../NewsFilters/FilterDialog.jsx';
import { newsConstants } from '../../../../Constants/NewsConstants';
import NewsMaxDialog from '../NewsTabs/NewsMaxDialog.jsx'
import DeleteNewsDialog from "../NewsFilters/DeleteNewsDialog.jsx";
import StringUtil from "StringUtil";
import ScrollBar from "ScrollBar";
import { updateScrollFlag } from '../../../../Actions/NewsActions';


const NewsPage = (
    {
        streamerList,
        dragStreamer,
        streamer,
        newsState,
        sourceData,
        filtersymbolData,
        listData,
        deleteNewsData,
        scrollflag,
        dispatch,
        isnameChanged,
        getFilterList,
        filterListValidation
    }) => {
    const emptyTab = streamerList.length < newsConstants.MAX - 2 ? newsConstants.MAX - 3 - streamerList.length : 0;
    const selectedStreamer = streamer.selectedStreamer;
    const addNewTabs = streamerList.length <= newsConstants.MAX - 1;
    const streamerListLength = streamerList.length - 1;    
    const isLoading = streamerListLength >-1 && streamerList[streamerListLength].MessageVisibility;

    const handleScroll = () => (div) => {     
        if (scrollflag)
            if (!StringUtil.isEmpty(div))
                div.scrollLeft = streamerList.length * 100;
        dispatch(updateScrollFlag(false));
    }
    return (
        <div className="panarayConsole news_block">
            {
                sourceData.showSource && selectedStreamer &&
                <SourceDialog
                    streamerList={streamerList}
                    selectedStreamer={selectedStreamer}
                    newsState={newsState}
                    sourceData={sourceData}                    
                    dispatch={dispatch}
                    data-test-id="source-dialog"
                />
            }            
            {
                sourceData.showFilter && selectedStreamer &&
                // <ErrorBoundary>
                    <FilterDialog
                        streamerList={streamerList}
                        selectedStreamer={selectedStreamer}
                        newsState={newsState}
                        sourceData={sourceData}
                        filtersymbolData={filtersymbolData}    
                        listData={listData}                                        
                        dispatch={dispatch}
                        filterListValidation={filterListValidation}
                        data-test-id="filter-dialog"
                    />
                // </ErrorBoundary>
            }
            {
                sourceData.showMax &&
                <NewsMaxDialog
                    x_parent={400}
                    y_parent={200}
                    dispatch={dispatch}
                    data-test-id="name-dialog"
                />
            }
            {
                deleteNewsData && selectedStreamer &&
                <DeleteNewsDialog                    
                    selectedStreamer={selectedStreamer}
                    streamerList={streamerList}
                    // deleteNewsData={deleteNewsData}
                    dispatch={dispatch}
                />
            }

            <div className="extender" data-test-id='main-div'>
                <div className="COLS">
                    <div className="CELL _FLEX panaray-container">
                        <div id="mainBlock">
                            <div id="page-content-wrapper">
                                <div className="page-content">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div id="news_container">
                                                <div className="mainBlockWrap" >
                                                    <div id="tabMainBlock">
                                                        <div id="NewsScroll" ref={handleScroll()} style={{ height: '100%', width: '100%', overflow: 'auto', position: 'absolute' }}>
                                                            <div className="custom-scroll" style={{ width: '100%', display: 'flex' }}>
                                                                {
                                                                    streamerList.map((rowstreamer, key) =>
                                                                        <TabsPage
                                                                            streamerList={streamerList}
                                                                            dragStreamer={dragStreamer}
                                                                            streamer={rowstreamer}
                                                                            dispatch={dispatch}
                                                                            key={key}
                                                                            newsState={newsState}
                                                                            filtersymbolData={filtersymbolData}
                                                                            listData={listData}
                                                                            sourceData={sourceData}
                                                                            selectedStreamer={selectedStreamer}
                                                                            isnameChanged={isnameChanged}  
                                                                            getFilterList={getFilterList}                                                                     

                                                                        />
                                                                    )
                                                                }
                                                                {
                                                                    addNewTabs &&
                                                                    <TabAdd isLoading={isLoading} dispatch={dispatch} />
                                                                }
                                                                {[...Array(emptyTab)].map((x, i) =>
                                                                    <EmptyTab
                                                                        key={i}
                                                                    />
                                                                )}
                                                            </div>
                                                            <ScrollBar scrollId='NewsScroll' hScroll={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsPage;
