import React from "react";

import FlexibleModal from "../../../../RayCustomControls/FlexibleModal.jsx";
import SourcePage from "./SourcePage.jsx";

const SourceDialog = (
    {
        streamerList,
        selectedStreamer,
        newsState,
        sourceData,       
        x_parent,
        y_parent,
        dispatch
    }) => {
    const getXPosition = () => {
        let x = 0;
        let w = 600;

        if (x_parent) {
            x = x_parent - (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2;
        }

        if (x < 0)
            x = 0;

        return x;
    }

    const getYPosition = () => {
        let y = 0;
        let h = 620;

        if (y_parent) {
            y = y_parent - (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2;
        }

        if (y < 0)
            y = 0;

        return y;
    }

    return (
        <FlexibleModal left={getXPosition()} top={getYPosition()} open={true} data-test-id="main-div" isDefaultDragEnabled = {false}
            className="modal-popup modal-dragable modal-sources-filter">
            <SourcePage
                streamerList={streamerList}
                selectedStreamer={selectedStreamer}
                newsState={newsState}
                sourceData={sourceData}           
                dispatch={dispatch}
            />
            <div className="clearfix"></div>
        </FlexibleModal>

    );
}

export default SourceDialog;