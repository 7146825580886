import React from "react";
import { Modal } from "react-bootstrap";
import { updateStreamList, setDeleteNews, setSelectedStream } from '../../../../Actions/NewsActions';
import LocalizationStore from 'LocalizationStore';

const DeleteNewsPage = ({
    streamerList, selectedStreamer, dispatch
}) => {

    const updateStream = () => {
        const filterdStreamerList = streamerList.filter((item) => item.StreamID !== selectedStreamer.StreamID);
        let startUpdate = false;
        const streamIDtoUpdate = selectedStreamer.StreamID - 1;
        const LayoutOrdertoUpdate = selectedStreamer.LayoutOrder + 1;

        filterdStreamerList.map((item) => {
            if(item.StreamID <= streamIDtoUpdate){
                item.StreamID = item.StreamID + 1;
            }
            if (!startUpdate && item.LayoutOrder === LayoutOrdertoUpdate)
                startUpdate = true;
            if (startUpdate) {
                item.LayoutOrder = item.LayoutOrder - 1;
            }
        });
        dispatch(updateStreamList(filterdStreamerList));
        dispatch(setDeleteNews(false));
        dispatch(setSelectedStream(null));
    }

    const updateShowNewsDelete = () => {
        dispatch(setDeleteNews(false));
        dispatch(setSelectedStream(null));
    }
    return (
        <div className="news-filter-block" data-test-id="main-div">
            <Modal.Header>
                <Modal.Title>
                    <span className="cap-header">{LocalizationStore.getTranslatedData("news_streamer","DELETE NEWS STREAMER")}</span>
                    <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut"  onClick={updateShowNewsDelete}>
                        <span className="icn-close"></span>
                    </button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="disable-drag">
                <div className="modal-material">
                    <p>{LocalizationStore.getTranslatedData("news_delete","Do you want to delete  the news stream {0} ?", `"${selectedStreamer.StreamName}"`)}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn-box righter">
                    <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={updateShowNewsDelete}>{LocalizationStore.getTranslatedData("cancel","CANCEL")} </button>
                    <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={updateStream}>{LocalizationStore.getTranslatedData("ok","OK")}</button>
                </span>
            </Modal.Footer>
        </div>
    );
}
export default DeleteNewsPage;