import React from 'react';

const Loading = ({ message }) => {
    return (
        <div className="flexbox-parent minWidth300" data-test-id='main-div'>
            <div className="flexbox-item fill-area content flexbox-item-grow">
                <div className="fill-area-content flexbox-item-grow">
                    <div className="infoContainer">
                        <div className="inner-infoContainer news-block">
                            <div className="newsInfo">
                                <div className="list-group">
                                    <div className="emt-tab-news">
                                        {message}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Loading;
