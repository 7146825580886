import React from "react";
import { Modal, ControlLabel, FormGroup, Form } from "react-bootstrap";
import LocalizationStore from "LocalizationStore";
import { setSource, updateStreamList, setSelectedStream, setNameChanged,saveStreamList } from '../../../../Actions/NewsActions';
import NameData from './NameData.jsx';

const NamePage = ({
    streamerList,
    selectedStreamer,
    streamer,
    isnameChanged,
    dispatch
}) => {
    const selectedLayoutOrder = streamer && streamer.LayoutOrder;
    const updateName = (event) => {        
        event.preventDefault();
        streamer.StreamName = streamer.tempStreamName.trim();
        streamer.tempStreamName="";
        streamerList.splice(selectedLayoutOrder, 1, streamer);
        if (streamer.StreamName == "") {
            streamer.StreamName = LocalizationStore.getTranslatedData("news_title","Untitled News Stream");
        }
        dispatch(updateStreamList(streamerList));
        // const sourceState = {
        //     showSource: false,
        //     showStreamName: false,
        //     showFilter: false
        // }
        streamer.enableNamePopup = false;
        dispatch(setNameChanged(false));
       // dispatch(setSource(sourceState));
        dispatch(setSelectedStream(null));
    }
    const updateshowStreamName = () => {
        // const sourceState = {
        //     showSource: false,
        //     showStreamName: false,
        //     showFilter: false
        // }
        streamer.enableNamePopup = false;
        // const sourceState = {
        //     showSource: false,
        //     showStreamName: true,
        //     showFilter: false
        // }
        if (streamer.StreamName == "") {
            streamer.StreamName = LocalizationStore.getTranslatedData("news_title","Untitled News Stream");
        }
        dispatch(saveStreamList(streamerList));
        dispatch(setNameChanged(false));
        //dispatch(setSource(sourceState));
        dispatch(setSelectedStream(null));
    }

    return (
        <div className="modal-dialog custom-width-pop disable-drag" data-test-id="main-div">
            <div role="document" className="modal-content">
                <div className="name-page-wrap" data-test-id="main-div">
                    <div className="modal-header">
                        <h4 className="modal-title">{LocalizationStore.getTranslatedData("news_stream","Stream Name")}</h4>
                    </div>
                    <div className="modal-body">
                        <div className="modal-area disable-drag">
                            <Form inline onSubmit={updateName}>
                                <FormGroup controlId="formInlineName">
                                    <ControlLabel>
                                        <NameData selectedStreamer={streamer} isnameChanged={isnameChanged} dispatch={dispatch} />
                                    </ControlLabel>
                                </FormGroup>
                            </Form>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={updateshowStreamName} >{LocalizationStore.getTranslatedData("cancel","CANCEL")}</button>
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={updateName} >{LocalizationStore.getTranslatedData("LM_TP_Save","SAVE")}</button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NamePage;
