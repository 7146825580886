import React from 'react';
import LocalizationStore from "LocalizationStore";
import { setSelectedStream, setNameChanged } from '../../../../Actions/NewsActions';

const NameData = ({
                    selectedStreamer,
                    isnameChanged,
                    dispatch
                }) => {
    const handleChangeNameFilter = (event) => {
        selectedStreamer.tempStreamName = event.target.value;        
        dispatch(setSelectedStream(selectedStreamer));
        if (!isnameChanged)
            dispatch(setNameChanged(true));
    }

    // const onselect = () => (input) => {
    //     if (input && !isnameChanged)
    //         input.select();
    // }

    const onclick = () => (input) => {        
        if(input)        
        input.target.focus();
    }

    const onfocus = () => (input) => {        
        if(input)
        input.target.select();
    }

    selectedStreamer.tempStreamName=!isnameChanged?selectedStreamer.StreamName:selectedStreamer.tempStreamName;
    
    return (
            <input autoFocus={true} autoComplete="off" className="form-control" data-test-id="main-div" name='name' type="text" placeholder={LocalizationStore.getTranslatedData('set_shar_n', 'Name')} maxLength="25" value={selectedStreamer && selectedStreamer.tempStreamName} onChange={handleChangeNameFilter} onClick={onclick()} onFocus={onfocus()} />
            // ref={onselect()}
    );
}

export default NameData;