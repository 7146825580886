import React from "react";

import { filter, find } from "underscore";

import NewsSources from "./NewsSources.jsx";

import { setSelectedStream } from "../../../../Actions/NewsActions";
import { FormGroup, Col } from "react-bootstrap";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore.js";

const NewsParents = ({
  selectedStreamer,
  newsType,
  newsTypeParent,
  dispatch
}) => {
  const newsSources = filter(
    selectedStreamer.Sources,
    (rowsSource) =>
      rowsSource.newsType === newsType && rowsSource.parents === newsTypeParent
  );

  const isAllSeleted = find(
    newsSources,
    (rowsSource) => rowsSource.isSelect === false
  );

  const selectedSource = filter(
    newsSources,
    (rowsSource) =>
      rowsSource.newsType === newsType && rowsSource.isSelect === true
  );

  const isNothingSelected = selectedSource.length === 0;

  const handleSelectNewsParent = event => {
    if (event.target.checked) {
      selectedStreamer.Sources.map(rowStreamSource => {
        if (
          rowStreamSource.newsType === newsType &&
          rowStreamSource.parents === newsTypeParent
        ) {
          rowStreamSource.isSelect = true;
        }
      });
    } else {
      selectedStreamer.Sources.map(rowStreamSource => {
        if (
          rowStreamSource.newsType === newsType &&
          rowStreamSource.parents === newsTypeParent
        ) {
          rowStreamSource.isSelect = false;
        }
      });
    }
    selectedStreamer.FilterFlag = true;
    dispatch(setSelectedStream(selectedStreamer));
  };

  return (
    <div className="socurce-list" data-test-id="main-div">
      <div className="parent-wrap">
        <div className="perent-head">
          <input
            id={"Parent-" + newsTypeParent + "-selected"}
            className="custom-check"
            type="checkbox"
            checked={isAllSeleted ? false : true}
            onChange={handleSelectNewsParent}
          />
          <label
            className={isNothingSelected || !isAllSeleted ? "txt-head xx-small-bold" : "txt-head xx-small-bold checkSelect"}
            htmlFor={"Parent-" + newsTypeParent + "-selected"}
          >
            <span />
            {LocalizationStore.getTranslatedData("news_"+newsTypeParent.replace(/ /ig,""),newsTypeParent)}
          </label>
        </div>
      </div>
     {/*  <div className="parent-child-wrap">
        <div className="perent-head">
          {newsSources &&
            newsSources.length > 1 &&
            newsSources.map((newsSource, key) => (
              <NewsSources
                selectedStreamer={selectedStreamer}
                newsType={newsType}
                newsTypeParent={newsTypeParent}
                newsSource={newsSource}
                dispatch={dispatch}
                key={key}
              />
            ))}
        </div>
      </div> */}
    </div>
  );
};

export default NewsParents;
