import React from 'react';
import ScrollBar from "../../../../RayCustomControls/ScrollBar/ScrollBar.jsx";
import TabData from './TabData.jsx';
import Header from './Header.jsx';
import LocalizationStore from "LocalizationStore";

const TabLayout = ({ streamerList, dragStreamer, streamer, dispatch, newsState, filtersymbolData, listData, sourceData, isnameChanged, selectedStreamer }) => {
    let topPosition = -1;
    const streamerListLength = streamerList.length;    
    function arrayUnique(arr, name) {
        var hash = {};
        return arr.reduce(function (item, next) {
            hash[next[name]] ? '' : hash[next[name]] = true && item.push(next);
            return item;
        }, []);
    }
    const streamerNewsList = arrayUnique(arrayUnique(streamer.NewsList, "url"), "title");

    return (
        <div className="flexbox-parent minWidth300" data-test-id="tab-layout" draggable={dragStreamer != null}>
            <div className="flexbox-item fill-area content flexbox-item-grow">
                <div className="fill-area-content flexbox-item-grow">
                    <div className="infoContainer main-naws-tab">
                        <div className="inner-infoContainer news-block">
                            <div className="newsInfo news-tab-inner">
                                <Header
                                    streamerList={streamerList}
                                    listData={listData}
                                    streamer={streamer}
                                    selectedStreamer={selectedStreamer}                                    
                                    isnameChanged={isnameChanged}
                                    dispatch={dispatch}                                    
                                />
                                <div className="list-group" >
                                    <div className="emt-tab-news">

                                        <div id={streamer.LayoutOrder}>
                                            <div className="custom-scroll">
                                                {
                                                    streamerNewsList.length > 0
                                                        ?
                                                        streamerNewsList.map((rowsNews, key) => {
                                                            topPosition = topPosition + 1;
                                                            return <TabData                                                                
                                                                streamerListLength={streamerListLength}
                                                                newsData={rowsNews}
                                                                topPosition={topPosition}
                                                                streamer={streamer}
                                                                key={key}
                                                                dispatch={dispatch}                                                            
                                                            />
                                                        }
                                                        )
                                                        :
                                                        <div className="x-large-normal no-news-found btn-center-news">
                                                            <div className="text-center">  {LocalizationStore.getTranslatedData("ri_id93_01", "No News Found")} </div>
                                                        </div>
                                                }
                                            </div>
                                            <ScrollBar scrollId={streamer.LayoutOrder} vScroll={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TabLayout;
