import React from "react";

import { filter, find, reject } from "underscore";

import NewsParents from "./NewsParents.jsx";
import ScrollBar from "ScrollBar";
import LocalizationStore from 'LocalizationStore';
import { setSelectedStream } from "../../../../Actions/NewsActions";

const NewsType = ({ selectedStreamer, newsType, dispatch }) => {
  const typeWiseNewsSource = filter(
    selectedStreamer.Sources,
    rowsSource => rowsSource.newsType === newsType
  );
  const isAllSeleted = find(
    typeWiseNewsSource,
    rowsSource => rowsSource.isSelect === false
  );

  const selectedSource = filter(
    selectedStreamer.Sources,
    (rowsSource) =>
    rowsSource.newsType === newsType && rowsSource.isSelect === true
  );

  const isNothingSelected = selectedSource.length === 0;

  const newsTypeParents = typeWiseNewsSource &&
    typeWiseNewsSource.length > 0 && [
      ...new Set(typeWiseNewsSource.map(item => item.parents))
    ];

  const handleSelectNewsType = event => {
    if (event.target.checked) {
      selectedStreamer.Sources.map(rowStreamSource => {
        if (rowStreamSource.newsType === newsType) {
          rowStreamSource.isSelect = true;
        }
      });
    } else {
      selectedStreamer.Sources.map(rowStreamSource => {
        if (rowStreamSource.newsType === newsType) {
          rowStreamSource.isSelect = false;
        }
      });
    }
    selectedStreamer.FilterFlag = true;
    dispatch(setSelectedStream(selectedStreamer));
  };

  const newTypeStyle =
    newsType === "Financial News" ? { height: "287px" } : { height: "144px" };
  return (
    <div className={`select-source-news financial-news ${newsType}`}>
      <div className="sources-heading">
        <input
          className="custom-check"
          id={"check-" + newsType + "-selected"}
          type="checkbox"
          checked={isAllSeleted ? false : true}
          onChange={handleSelectNewsType}
        />
        <label
          className={
            isNothingSelected || !isAllSeleted ? "" : "checkSelect"
          }
          htmlFor={"check-" + newsType + "-selected"}
        >
          <span />
          {LocalizationStore.getTranslatedData("news_"+newsType.replace(/ /ig,""),newsType)}
        </label>
      </div>
      <div className="sources-body">
        <div style={newTypeStyle} className="custom-scroll-light">
          <div className="custom-scroll">
            <div id={newsType}>
              <div className="tree-source">
                {newsTypeParents.map((newsTypeParent, key) => (
                  <NewsParents
                    selectedStreamer={selectedStreamer}
                    newsType={newsType}
                    newsTypeParent={newsTypeParent}
                    dispatch={dispatch}
                    key={key}
                  />
                ))}
              </div>
            </div>
          </div>
          <ScrollBar scrollId={newsType} hScroll={true} />
        </div>
      </div>
    </div>
  );
};

export default NewsType;
