import React from "react";
import LocalizationStore from "LocalizationStore";
import { Modal, ControlLabel, FormGroup, Form, Table } from "react-bootstrap";
import { filter, find } from 'underscore';

import { setSource, fetchNewsList, setSelectedStream, updateStreamList } from '../../../../Actions/NewsActions';
import { newsActions } from '../../../../Constants/NewsConstants';

import SourceData from './SourceData.jsx';

const SourcePage = ({
    streamerList,
    selectedStreamer,
    newsState,
    sourceData,
    dispatch
}) => {
    const selectedLayoutOrder = selectedStreamer.LayoutOrder;

    const updateStream = (event) => {
        var code = event.keyCode || event.which;
        if ((code === 13) || (!code)) {
             selectedStreamer.MessageVisibility = true;
            streamerList.splice(selectedLayoutOrder, 1, selectedStreamer);            
                dispatch(fetchNewsList(
                    streamerList,
                    newsState,
                    selectedLayoutOrder,
                    newsActions.UPDATE)
                );            
            const sourceState = {
                showSource: false,
                showStreamName: false
            }
            dispatch(setSelectedStream(null));
            dispatch(setSource(sourceState));
        }
    }


    const updateShowSource = (event) => {
        if(selectedStreamer.isNeedRefreah){
            const stateData = selectedStreamer.SourcesSelectStateBackup;
            if(stateData){
                const stateParents = [...new Set(stateData.map(item => item.parents))];
                stateParents.forEach((itemParents) => {
                    const itemState = find(stateData, (item) => item.parents === itemParents);
                    let sourceItems = filter(selectedStreamer.Sources, (item) => item.parents === itemParents);
                    if(sourceItems && sourceItems.length > 0){
                        sourceItems.forEach((item) => { item.isSelect = itemState.isSelect });
                    }
                });
            }
            selectedStreamer.MessageVisibility = true;
            streamerList.splice(selectedLayoutOrder, 1, selectedStreamer);    
            dispatch(fetchNewsList(
                streamerList,
                newsState,
                selectedLayoutOrder,
                newsActions.UPDATE)
            ); 
        }
        const sourceState = {
            showSource: false,
            showStreamName: false
        }
        dispatch(setSelectedStream(null));
        dispatch(setSource(sourceState));

    }

    return (
        <div className="select-source-box" data-test-id="main-div" onKeyPress={updateStream}>
            <Modal.Header>
                <Modal.Title className="enable-drag">
                    <span className="cap-header">{LocalizationStore.getTranslatedData("news_select","SELECT SOURCES")}</span>
                    <button
                        type="button"
                        className="btn btn-xxs btn-default btn-secondary btn-close"
                        data-effect="fadeOut"
                        onClick={updateStream}
                    >
                        <span className="icn-close enable-drag"></span>
                    </button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-area">
                    {/* <div className="select-source-region"> */}
                        <SourceData
                            selectedStreamer={selectedStreamer}
                            dispatch={dispatch}
                        />
                    {/* </div> */}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn-box righter">
                    <button type="button"
                            className="btn btn-xs btn-default btn-secondary"
                            data-effect="fadeOut"
                            onClick={updateShowSource}
                    >
                        {LocalizationStore.getTranslatedData("set_cancel","CANCEL")}
                    </button>
                    <button type="button"
                            className="btn btn-xs btn-default btn-secondary"
                            data-effect="fadeOut"
                            onClick={updateStream}
                    >
                        {LocalizationStore.getTranslatedData("ok","OK")}
                    </button>
                </span>
            </Modal.Footer>
            <div className="clearfix"></div>
        </div>
    );
}

export default SourcePage;
