import React from 'react'
import BaseServiceApi from 'BaseServiceApi';
import { setList, setSymbol } from '../../../../../Actions/NewsActions';
import { reject, findIndex, filter } from "underscore";
import StringUtil from "StringUtil";
import LocalizationStore from 'LocalizationStore';
let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
let ListType = BaseServiceApi.rayData["ListType"];
let ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

const TreeListNode = (
    {
        filtersymbolData,
        list,
        rowslist,
        containerClassName,
        dispatch
    }
) => {

    let arrow, folder, fdName, olist = null;
    let listNode = (rowslist.listType === ListType.LIST_List ? true : false);
    let collapsed = !rowslist.expandNode;
    let rootNode = (rowslist.listType === ListType.CATEGORY_List ? true : false);
    let folderNode = (rowslist.listType === ListType.FOLDER_List ? true : false);
    const isActiveNode = filtersymbolData.selectedSymbol && filtersymbolData.selectedSymbol[0].listId === rowslist.nodeId.low;
    let IsVisible = rowslist.isVisible;
    let arrowClassName = (rootNode ? 'icn-tree-view_arrow' : '');
    let folderClassName = (folderNode ? 'fa icn-folder-view-open' : '');

    const selectCurrentNode = (event) => {
        let newSymbol = [];
        newSymbol.push({
            listname: rowslist.name,
            listId: rowslist.nodeId.low,
            listType: rowslist.listType,
            selectedFilterListId: rowslist.nodeId.low
        })
        const newFilterData = {
            showSymbol: true,
            data: filtersymbolData.data,
            selectedSymbol: filtersymbolData.selectedSymbol,
            tempSelectedSymbol: newSymbol,
            IsAndOperatorChecked: filtersymbolData.IsAndOperatorChecked,
            searchSymbol: filtersymbolData.searchSymbol,
            phrasesKeywords: filtersymbolData.phrasesKeywords,
        }
        dispatch(setSymbol(newFilterData));
        
    }
    const OpenArrow = (event) => {
        rowslist.expandNode = !rowslist.expandNode;
        dispatch(setList(list));
    }

    if (IsVisible) {
        if (collapsed) {
            if (arrowClassName != '') {
                arrowClassName += ' icn-tree-view_arrow-collapsed';
            }
            containerClassName += ' tree-view_children-collapsed folder-level-3';
            if (folderClassName != '')
                folderClassName += 'fa icn-folder-view';
        }
        if (folderClassName != '') {
            folder = <div className={folderClassName} id={rowslist.nodeId.low} onClick={OpenArrow} ></div>
            fdName = <span data-node-id={rowslist.nodeId.low} onClick={OpenArrow}>
                <span className={(rowslist.listType != ListType.CATEGORY_List) ? "tree-node categoryClass list-no-whiteSpace " : "tree-node categoryClass list-no-whiteSpace "}
                    data-node-id={rowslist.nodeId.low}>
                    {LocalizationStore.getTranslatedData("TreeExplorer_FOL_"+ rowslist.name.replace(/ /ig,""),rowslist.name)}
                </span>
            </span>
        }
        if (arrowClassName != '') {
            arrow = <div className={arrowClassName} id={rowslist.nodeId.low} onClick={OpenArrow}>
            </div>
            fdName = <span data-node-id={rowslist.nodeId.low} onClick={OpenArrow}>
                <span className={(rowslist.listType != ListType.CATEGORY_List) ? "tree-node categoryClass list-no-whiteSpace " : "tree-node categoryClass list-no-whiteSpace "}
                    data-node-id={rowslist.nodeId.low}>
                    {LocalizationStore.getTranslatedData("TreeExplorer_CAT_"+ rowslist.name.toUpperCase().replace(/ /ig,""),rowslist.name.toUpperCase())}
                </span>

            </span>
        }
        let listClassName = 'userList ';
        if (rowslist.listType === ListType.LIST_List) {
            switch (rowslist.categoryType) {
                case ListCategoryType.FAVORITE_ListCategory:
                    listClassName += 'fa icn-fa-file-text-fav';
                    break;

                case ListCategoryType.USER_ListCategory:
                case ListCategoryType.SCHEDULESCREENLIST_ListCategory:
                    listClassName += 'fa icn-fa-file-text-userlist';
                    break;

                case ListCategoryType.APLIST_ListCategory:
                    listClassName += 'fa icn-fa-file-text-ap';
                    break;

                case ListCategoryType.SHAREDLIST_ListCategory:
                    if (!StringUtil.isEmpty(rowslist.shareAccess))
                        listClassName += rowslist.shareAccess === ShareAccessType.SHARE_READANDEDIT ? 'icn-editable' : 'fa icn-fa-file-text-userlist'
                    break;

                default:
                    listClassName += 'fa icn-fa-file-text-p';
                    break;
            }
            const isActiveList = filtersymbolData && filtersymbolData.tempSelectedSymbol && filtersymbolData.tempSelectedSymbol.length > 0 && filtersymbolData.tempSelectedSymbol[0].listname === rowslist.name && filtersymbolData.tempSelectedSymbol[0].selectedFilterListId === rowslist.nodeId.low ? "active-list" : "";
            olist = <span onClick={selectCurrentNode} style={{ position: 'relative' }} className={`listCategoryClass list-no-whiteSpace list-no-Border ${isActiveList}`} data-node-id={rowslist.nodeId.low}>
                <i className={listClassName} data-node-id={rowslist.nodeId.low}></i>
                <span style={{ verticalAlign: 'middle' }} data-node-id={rowslist.nodeId.low} >{LocalizationStore.getTranslatedData("TreeExplorer_LST_"+ rowslist.name.replace(/ /ig,""), rowslist.name)}</span>
            </span>
        }

        const className = isActiveNode ?
            'tree-view_item  active medium-normal child-item  ' :
            'tree-view_item  medium-normal child-item ';
        return (
            <div data-node-id={rowslist.nodeId.low} className={className}>
                {arrow}
                {(rootNode || folderNode) &&  
                    <span style={{ height: "22px", border: "1px solid transparent" }} id={rowslist.nodeId.low} >{folder}
                        {fdName}
                    </span>
                }
                {olist}
            </div>

        )
    }
}
export default TreeListNode;


