import React from "react";
import { FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import { setSymbol } from '../../../../Actions/NewsActions';
import LocalizationStore from 'LocalizationStore';

const FilterKeyWords = ({
    filtersymbolData,
    dispatch
}) => {
    const handleChangeKeyndPhrase = (event) => {
        const fsymboldata = {
            showSymbol: false,
            data: filtersymbolData.data,
            selectedSymbol: filtersymbolData.selectedSymbol,
            searchSymbol: filtersymbolData.searchSymbol,
            phrasesKeywords: event.target.value,
            IsAndOperatorChecked:filtersymbolData.IsAndOperatorChecked
        }
        dispatch(setSymbol(fsymboldata));
    }
    return (
         <div className="news-filter-box">
            <FormGroup controlId="formControlsTextarea">
                <ControlLabel>{LocalizationStore.getTranslatedData("news_keys","KEYWORDS AND PHRASES")}</ControlLabel>
                <FormControl    data-test-id="main-div"
                                componentClass="textarea"
                                resize= 'none'
                                placeholder={LocalizationStore.getTranslatedData("news_tips",'Enter any combination of keywords and phrases, separated by a space. Use "quotes" for phrases')}
                                onChange={handleChangeKeyndPhrase}
                                value={filtersymbolData.phrasesKeywords}
                />
            </FormGroup>
            <div className="clearfix"></div>
        </div>
    )
}
export default FilterKeyWords;






   