import React from "react";
import FlexibleModal from "../../../../RayCustomControls/FlexibleModal.jsx";
import {setSource } from '../../../../Actions/NewsActions';
import { Modal, ControlLabel, FormGroup, Form, Table } from "react-bootstrap";
import LocalizationStore from 'LocalizationStore';
const NewsMaxDialog = (
    {
        x_parent,
        y_parent,
        dispatch
    }) => {
    const getXPosition = () => {
        let x = 0;
        let w = 280;

        if (x_parent) {
            x = x_parent - (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2;
        }

        if (x < 0)
            x = 0;

        return x;
    }

    const getYPosition = () => {
        let y = 0;
        let h = 260;

        if (y_parent) {
            y = y_parent - (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2;
        }

        if (y < 0)
            y = 0;

        return y;
    }

    const updatenewsNotification = (event) => {
        const sourceState = {
            showSource: false,
            showStreamName: false,
            showFilter: false,
            showMax: false
        }
        dispatch(setSource(sourceState));
    }

    return (
        <FlexibleModal left={getXPosition()} top={getYPosition()} open={true} data-test-id="main-div" draggable="false" className="modal-popup news-stream-limit">
            <Modal.Header>
                <Modal.Title>
                    <span className="cap-header">{LocalizationStore.getTranslatedData("news_streamlimit","NEWS STREAM LIMIT")}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="disable-drag">
                <div className="modal-region disable-drag">
                    <p>{LocalizationStore.getTranslatedData("news_streamlimitmsg","Maximum quantity of news streams has already been reached. Another news stream cannot be created")}</p>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn-box center">
                    <button type="button" data-effect="fadeOut" onClick={updatenewsNotification} className="btn btn-xs btn-default btn-secondary"> {LocalizationStore.getTranslatedData("ok","OK")} </button>
                </span>
            </Modal.Footer>
        </FlexibleModal>
    );
}
export default NewsMaxDialog;