import React from 'react';
import { pure } from 'recompose';

const EmptyTab = () => {
    return (
        <div className="flexbox-parent minWidth300" data-test-id="empty-tab">
            <div className="flexbox-item fill-area content flexbox-item-grow">
                <div className="fill-area-content flexbox-item-grow">
                    <div className="infoContainer">
                        <div className="inner-infoContainer news-block">
                            <div className="newsInfo">
                                <div className="list-group">
                                    <div className="emt-tab-news">                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default pure(EmptyTab);
