import React from 'react';

import Loading from './Loading.jsx';
import TabLayout from './TabLayout.jsx';

const TabsPage = ({ streamerList, dragStreamer, streamer, dispatch,newsState, filtersymbolData, listData, sourceData,isnameChanged,selectedStreamer,getFilterList}) => {

    return (
        streamer.MessageVisibility || getFilterList
            ?
            <Loading message={streamer.MessageText} />
            :
            <TabLayout streamerList={streamerList} streamer={streamer} dispatch={dispatch} newsState={newsState} filtersymbolData={filtersymbolData} listData={listData} sourceData={sourceData} isnameChanged={isnameChanged} selectedStreamer={selectedStreamer} dragStreamer={dragStreamer} />
    );
}

export default TabsPage;
