import React from "react";
import { FormControl, FormGroup, Radio } from "react-bootstrap";
import { setSymbol } from '../../../../Actions/NewsActions';
import LocalizationStore from 'LocalizationStore';

const FilterCondtional = ({
    filtersymbolData,
    dispatch
}) => {
    const handleOperatorChange = (event) => {
        const fltSymbolData = {
            showSymbol: false,
            data: filtersymbolData.data,
            selectedSymbol: filtersymbolData.selectedSymbol,
            searchSymbol: filtersymbolData.searchSymbol,
            phrasesKeywords: filtersymbolData.phrasesKeywords,
            IsAndOperatorChecked: JSON.parse(event.target.value)
        }
        dispatch(setSymbol(fltSymbolData))
    }
    return (
        <div className="and-or-radio">
            <ul className="and-or-btn" data-test-id="main-div">
                <li>
                    <input  id="radio1"
                            type="radio"
                            name="radio-button"
                            onChange={handleOperatorChange}
                            name="radio-button"
                            data-report-templateid="1"                    
                            value="false"
                            checked={!filtersymbolData.IsAndOperatorChecked}
                    />
                    <label  htmlFor="radio1">
                        {LocalizationStore.getTranslatedData("new_and", "and")}
                    </label>
                </li>
                <li>
                    <input  id="radio2"
                            type="radio"
                            name="radio-button"
                            data-report-templateid="2"
                            onChange={handleOperatorChange}
                            name="radio-button"
                            value="true"
                            checked={filtersymbolData.IsAndOperatorChecked}
                    />
                    <label  htmlFor="radio2">
                        {LocalizationStore.getTranslatedData("news_or", "or")}
                    </label>
                </li>
            </ul>
        </div>
    )
}
export default FilterCondtional;


