import React from "react";
import { FormControl, FormGroup, ControlLabel  } from "react-bootstrap";
import { setSymbol } from '../../../../Actions/NewsActions';
import FilterSymbolList from './FilterSymbolList.jsx';
import LocalizationStore from 'LocalizationStore';

const FilterSymbols = ({
    filtersymbolData,
    selectedStreamer,
    dispatch
 
 
}) => {
    const handleChangeSymbol = (event) => {
        const fsymboldata = {
            showSymbol: false,
            data: filtersymbolData.data,
            selectedSymbol: filtersymbolData.selectedSymbol,
            searchSymbol: event.target.value,
            phrasesKeywords: filtersymbolData.phrasesKeywords,
            IsAndOperatorChecked:filtersymbolData.IsAndOperatorChecked,
        }
        dispatch(setSymbol(fsymboldata));

    }
    return (
         <div className="news-filter-box">
            <FormGroup controlId="formControlsTextarea">
                <ControlLabel>{LocalizationStore.getTranslatedData("news_sy","SYMBOLS")}</ControlLabel>
                <FormControl    data-test-id="main-div"
                                componentClass="textarea"
                                resize= 'none'
                                placeholder={LocalizationStore.getTranslatedData("news_placeholder",'Enter symbols,separated by a space')}
                                onChange={handleChangeSymbol}
                                value={filtersymbolData.searchSymbol}
                />
                <FilterSymbolList  filtersymbolData={filtersymbolData}
                                dispatch={dispatch}
                />
            </FormGroup>
            <div className="clearfix"></div>
        </div>
    )
}
export default FilterSymbols;



