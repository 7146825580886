import React from "react";

import FlexibleModal from "../../../../../RayCustomControls/FlexibleModal.jsx";
import SymbolPage from './SymbolPage.jsx';

const SymbolDialog = (
    {
        filtersymbolData,    
        listData,  
        selectedStreamer,
        x_parent,
        y_parent,
        dispatch
    }) => {
    const getXPosition = () => {
        let x = 0;
        let w = 280;

        if (x_parent) {
            x = x_parent - (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2;
        }

        if (x < 0)
            x = 0;

        return x;
    }

    const getYPosition = () => {
        let y = 0;
        let h = 260;

        if (y_parent) {
            y = y_parent - (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2;
        }

        if (y < 0)
            y = 0;

        return y;
    }

    return (
        <div className="internal-filter-list">
            <SymbolPage
                filtersymbolData={filtersymbolData}
                listData={listData}      
                selectedStreamer={selectedStreamer}
                dispatch={dispatch}
            />
        </div>

    );
}

export default SymbolDialog;
